import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { MdDelete } from "react-icons/md";

import { CustomAddButton, CustomButton } from "../components/CustomButton";
import CustomModal from "../components/CustomModal";
import CustomTextArea from "../components/CustomTextArea";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import SuperpoweredChatThread from "../components/SuperpoweredChatThread.tsx";

import {
  formatChatThreadTitle,
  sortChatThreadsByRecentlyUsed,
} from "../logic/chat.tsx";
import {
  createChatThread,
  deleteChatThread,
  listChatThreads,
  updateSystemMessage,
} from "../services/chat.tsx";
// import * as authActions from '../store/actions/auth';

import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import Sidebar from "../components/Sidebar/Sidebar.js";
import {
  setChatConfig,
  setSystemMessage,
} from "../features/auth/authSlice.ts";
import { useAppDispatch, useAppSelector } from "../hooks.ts";
import { KnowledgeBase, KnowledgeBaseType } from "../utils/knowledge_bases.ts";
import "./Chat.css";
import { InfoIconWithText } from "../components/InfoIcon.js";
import IosSwitch from "../components/IosSwitch.tsx";
import CustomDropdown, { CustomDropdownMultiple } from "../components/CustomDropdown.js";

const Chat = () => {
  const username = useAppSelector((state) => state.auth.user._id);
  const authToken = useAppSelector((state) => state.auth.idToken);
  const user = useAppSelector((state) => state.auth.user);

  let data = sessionStorage.getItem("data");
  if (data === undefined || data === null) {
    data = null;
  } else {
    data = JSON.parse(data);
  }

  const chatConfig = data !== null ? JSON.parse(data["chat_config"]) : {};

  /*const knowledgeBaseIds = [
    user.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.Compliance
    )[0].id,
    user.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.BrandPersonalization
    )[0].id
  ];*/
  const complianceKB = user.knowledge_bases.filter(
    (kb) => kb.name === KnowledgeBaseType.Compliance
  )[0];

  const [isLoading, setIsLoading] = useState(true);
  const [allChatThreads, setAllChatThreads] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [showSystemMessageModal, setShowSystemMessageModal] = useState(false);
  //const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([complianceKB.id]);
  const dispatch = useAppDispatch();
  // const [systemMessage, setSystemMessage] = useState(defaultSystemMessage == undefined ? "" : defaultSystemMessage);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [chatThreadToDelete, setChatThreadToDelete] = useState(null);

  async function createNewChatThread() {
    setIsLoading(true);
    const knowledgeBaseIds =
      chatConfig["knowledge_base_ids"] == undefined
        ? []
        : chatConfig["knowledge_base_ids"];

    const [resData, status] = await createChatThread(
      authToken,
      knowledgeBaseIds,
      user.chat_config?.model || "gpt-3.5-turbo",
      user.chat_config?.temperature || 0.2,
      user.chat_config?.responseLength || "medium",
      username,
      user.system_message || "",
      user.chat_config?.use_web_search || false,
      user.chat_config?.web_search_preset || null
    );
    if (status === 200) {
      setCurrentThreadId(resData.id);
      // Update all chat threads
      const newChatThreads = [...allChatThreads];
      newChatThreads.unshift(resData);
      console.log("newChatThreads", newChatThreads);
      setAllChatThreads(newChatThreads);
    } else {
      //TODO: Handle error
      // This probably means the api key and/or secret aren't valid
    }
    setIsLoading(false);
  }

  async function initialize(idToken) {
    setIsLoading(true);
    // Get the list of chat threads with the username as the supp id
    // const idToken = data["idToken"]
    const [resData, status] = await listChatThreads(idToken, username);
    console.log("resData", resData);
    console.log("status", status);

    if (status === 200) {
      const chatThreads = resData["chat_threads"];
      let nextPageToken = resData["next_page_token"];
      console.log("chatThreads", chatThreads);

      let sortedChatThreads = sortChatThreadsByRecentlyUsed(
        resData.chat_threads
      );
      if (chatThreads.length === 0) {
        // && nextPageToken === null) {
        console.log("creating new chat thread");
        createNewChatThread();
      } else if (chatThreads.length !== 0) {
        // Update the chat threads
        console.log("sortedChatThreads", sortedChatThreads);
        setAllChatThreads(sortedChatThreads);
        setCurrentThreadId(resData["chat_threads"][0]["id"]);
      }

      while (nextPageToken !== null) {
        const [newChatThreads, newStatus] = await listChatThreads(
          idToken,
          username,
          nextPageToken
        );
        console.log("newChatThreads", newChatThreads);
        const newNextPageToken = newChatThreads.next_page_token;
        console.log(newChatThreads, "newChatThreads chat");
        let newSortedChatThreads = sortChatThreadsByRecentlyUsed(
          newChatThreads.chat_threads
        );
        // Concatenate the chat threads
        console.log("newSortedChatThreads", newSortedChatThreads);
        console.log("sortedChatThreads", sortedChatThreads);
        if (newSortedChatThreads.length > 0) {
          sortedChatThreads = [...sortedChatThreads, ...newSortedChatThreads];
          console.log("sortedChatThreads", sortedChatThreads);
          setAllChatThreads(sortedChatThreads);
        }
        nextPageToken = newNextPageToken;
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert("There was an error initializing the chat thread");
    }
  }

  async function makeSystemMessageUpdateRequest(message, chat_config) {
    if (chat_config.use_web_search) {
      const webSearchPreset = chat_config.web_search_preset;
      const includeDomains = webSearchPreset.includeDomains;
      const excludeDomains = webSearchPreset.excludeDomains;
      let formattedIncludeDomains = [];
      let formattedExcludeDomains = [];

      if (includeDomains !== "") {
        formattedIncludeDomains = includeDomains
          .split(",")
          .map((domain) => domain.trim());
      }
      if (excludeDomains !== "") {
        formattedExcludeDomains = excludeDomains
          .split(",")
          .map((domain) => domain.trim());
      }
      const timeframe = webSearchPreset.timeframe;
      let formattedTimeframe = 7000;
      if (timeframe === "past_day") {
        formattedTimeframe = 1;
      }
      if (timeframe === "past_week") {
        formattedTimeframe = 7;
      }
      if (timeframe === "past_month") {
        formattedTimeframe = 30;
      }
      if (timeframe === "past_year") {
        formattedTimeframe = 365;
      }

      // For each domain, remove the protocol and any path
      formattedIncludeDomains = formattedIncludeDomains.map((domain) => {
        if (domain.includes("://")) {
          domain = domain.split("://")[1];
        }
        if (domain.includes("/")) {
          domain = domain.split("/")[0];
        }
        return domain;
      });

      formattedExcludeDomains = formattedExcludeDomains.map((domain) => {
        if (domain.includes("://")) {
          domain = domain.split("://")[1];
        }
        if (domain.includes("/")) {
          domain = domain.split("/")[0];
        }
        return domain;
      });
      chat_config.web_search_preset = {
        include_domains: formattedIncludeDomains,
        exclude_domains: formattedExcludeDomains,
        timeframe_days: formattedTimeframe,
      };
    } else {
      chat_config.web_search_preset = null;
    }

    if (chat_config.temperature) {
      chat_config.temperature = parseFloat(chat_config.temperature);
    }

    const res = await updateSystemMessage(
      authToken,
      username,
      user.companyId,
      message,
      chat_config
    );
    if (res === 200 || res === 204) {
      dispatch(setSystemMessage(message));
      dispatch(setChatConfig(chat_config));
    }
  }

  async function deleteThread() {
    setIsLoading(true);
    const status = await deleteChatThread(authToken, chatThreadToDelete);
    console.log("status", status);
    if (status === 200 || status === 204) {
      // Remove this thread from the list
      const newThreads = allChatThreads.filter(
        (thread) => thread.id !== chatThreadToDelete
      );
      console.log("newThreads", newThreads);
      setAllChatThreads(newThreads);
      // Set the current thread to the first thread in the list
      if (newThreads.length > 0) {
        setCurrentThreadId(newThreads[0].id);
      } else {
        // Create a new thread
        console.log("creating new chat thread");
        createNewChatThread();
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (data !== null) {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (authToken !== null && authToken !== undefined && authToken !== "") {
      initialize(authToken);
    }
  }, [authToken]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"post generator"} onPersonalizationClick={() => setShowSystemMessageModal(true)} />
      <div className="chat-section">
        {/* <Header darkMode={false} currentPage={"chat"} /> */}

        <div className="chat-container">
          <div className="chat-main-container">
            {/* {isLoading && <BouncingDots />} */}

            {showSystemMessageModal && (
              <SystemMessageModal
                isOpen={showSystemMessageModal}
                closeModal={() => setShowSystemMessageModal(false)}
                initialSystemMessage={user.system_message}
                onClick={(system_message, chat_config) => {
                  makeSystemMessageUpdateRequest(system_message, chat_config);
                  setShowSystemMessageModal(false);
                }}
                useWebSearchInput={user.chat_config?.use_web_search ?? false}
                modelInput={user.chat_config?.model ?? "gpt-3.5-turbo"}
                responseLengthInput={user.chat_config?.responseLength ?? "medium"}
                temperatureInput={user.chat_config?.temperature ?? 0.2}
                includeDomainsInput={
                  user.chat_config?.web_search_preset?.include_domains ?? ""
                }
                excludeDomainsInput={
                  user.chat_config?.web_search_preset?.exclude_domains ?? ""
                }
                timeframeInput={
                  user.chat_config?.web_search_preset?.timeframe_days ?? "any"
                }
                knowledgeBases={user.knowledge_bases}
                preSelectedKnowledgeBases={user.chat_config.knowledge_base_ids ?? []}
              />
            )}
            {showDeleteConfirmationModal && (
              <DeleteConfirmationModal
                isOpen={showDeleteConfirmationModal}
                closeModal={() => setShowDeleteConfirmationModal(false)}
                delete={() => {
                  deleteThread(chatThreadToDelete);
                  setShowDeleteConfirmationModal(false);
                }}
                title={"Delete chat thread"}
                confirmationText={`Are you sure you want to delete this chat thread?`}
              />
            )}

            {currentThreadId !== null ? (
              <SuperpoweredChatThread
                threadId={currentThreadId}
                isDarkMode={false}
                chatConfig={{
                  knowledgeBaseIds: user.chat_config.knowledge_base_ids,
                  systemMessage: user.system_message,
                  responseLength: "medium",
                  model: "claude-3-opus",
                  responseLength: "medium",
                }}
              />
            ) : (
              <BouncingDots />
            )}
          </div>

          <div className={`chat-sidebar-container-${"light"}`}>
            <div className="new-chat-thread-button-container">
              <CustomAddButton
                buttonText={"New chat thread"}
                onClick={() => createNewChatThread()}
                disabled={isLoading}
              />
            </div>
            <div className="all-chat-threads-container">
              {currentThreadId !== null &&
                allChatThreads.map((chatThread, index) => (
                  <ChatThread
                    key={index}
                    disableClick={isLoading}
                    isCurrentThread={currentThreadId === chatThread["id"]}
                    title={formatChatThreadTitle(chatThread)}
                    onClick={() => setCurrentThreadId(chatThread["id"])}
                    onDeleteClick={() => {
                      setShowDeleteConfirmationModal(true);
                      setChatThreadToDelete(chatThread["id"]);
                    }}
                  />
                ))}
            </div>

            {/* <div className="chat-config-container">
              <p
                className={`chat-config-text-light`}
                onClick={() => setShowSystemMessageModal(true)}
              >
                Edit Firm Personalization
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatThread = ({
  title,
  onClick,
  onDeleteClick,
  disableClick,
  isCurrentThread = false,
  theme = "light",
}) => {
  return (
    <div
      className={`chat-thread-container-${theme}${isCurrentThread ? ` chat-thread-container-selected-${theme}` : ""
        }`}
    >
      <div
        className={
          "chat-thread-title-col" +
          (disableClick ? " chat-thread-title-col-disabled" : "")
        }
        onClick={disableClick ? null : onClick}
      >
        <p className={`semi-bold-font-${theme} chat-thread-title-text`}>
          {title}
        </p>
      </div>
      <div
        className={
          "delete-chat-thread-col" +
          (disableClick ? " delete-chat-thread-col-disabled" : "")
        }
        onClick={disableClick ? null : onDeleteClick}
      >
        <IconContext.Provider
          value={{ className: `delete-chat-thread-icon-${theme}` }}
        >
          <div>
            <MdDelete />
          </div>
        </IconContext.Provider>
      </div>
    </div>
  );
};

const SystemMessageModal = ({
  isOpen,
  closeModal,
  initialSystemMessage,
  useWebSearchInput,
  includeDomainsInput = "",
  excludeDomainsInput = "",
  timeframeInput = "any",
  onClick,
  modelInput = "gpt-3.5-turbo",
  responseLengthInput = "medium",
  temperatureInput = 0.2,
  knowledgeBases,
  preSelectedKnowledgeBases
}) => {
  
  const lfgResponseLengtHelpText =
    "This parameter is used to control the final length of the response generated by the LLM. Short will create 2-4 sections with 2-4 paragraphs each, medium will create 3-5 sections with 3-5 paragraphs each, and long will create 5-7 sections with 3-5 paragraphs each.";

  const [systemMessage, setSystemMessage] = useState(initialSystemMessage);
  // Format the include and exclude domains if they come in as lists
  if (Array.isArray(includeDomainsInput)) {
    includeDomainsInput = includeDomainsInput.join(", ");
  }
  if (Array.isArray(excludeDomainsInput)) {
    excludeDomainsInput = excludeDomainsInput.join(", ");
  }

  const knowledgeBaseIds = knowledgeBases.map((kb) => kb.id);
  const knowledgeBaseTitles = knowledgeBases.map((kb) => kb.name);
  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState(preSelectedKnowledgeBases);
  console.log("preSelectedKnowledgeBases", preSelectedKnowledgeBases)

  const [useWebSearch, setUseWebSearch] = useState(useWebSearchInput || false);
  const [includeDomains, setIncludeDomains] = useState(includeDomainsInput);
  const [excludeDomains, setExcludeDomains] = useState(excludeDomainsInput);

  const [model, setModel] = useState(modelInput);
  const [responseLength, setResponseLength] = useState(responseLengthInput);
  const [temperature, setTemperature] = useState(temperatureInput);

  // Format the timeframe input
  console.log(timeframeInput, "timeframeInput");
  let formattedTimeframeInput = "any";

  // if timeframeInput is a string, convert it to a number
  if (typeof timeframeInput === "string") {
    formattedTimeframeInput = timeframeInput;
  }
  if (timeframeInput === 7000) {
    formattedTimeframeInput = "any";
  } else if (timeframeInput === 1) {
    formattedTimeframeInput = "past_day";
  } else if (timeframeInput === 7) {
    formattedTimeframeInput = "past_week";
  } else if (timeframeInput === 30) {
    formattedTimeframeInput = "past_month";
  } else if (timeframeInput === 365) {
    formattedTimeframeInput = "past_year";
  }
  console.log(formattedTimeframeInput, "formattedTimeframeInput");
  const [timeframe, setTimeframe] = useState(formattedTimeframeInput);

  return (
    <CustomModal
      closeModal={closeModal}
      isOpen={isOpen}
      customModalContainerStyle={{
        maxWidth: "800px",
        maxHeight: "95vh",
        overflowY: "auto",
      }}
      customMainColStyle={{ paddingTop: "0px", marginTop: "-10px" }}
    >
      <div className="custom-system-message-modal-container">
        <p className="semi-bold-font-light">Firm Personalization</p>
        <div
          className="llm-configuration-modal-options"
          style={{ alignItems: "flex-start" }}
        >

          <div className="llm-configuration-modal-dropdown">
            <p className="use-case-header-text">Knowledge bases:</p>
            <CustomDropdownMultiple
              valueList={knowledgeBaseIds}
              textList={knowledgeBaseTitles}
              value={selectedKnowledgeBases}
              sendDataToParent={(val) => setSelectedKnowledgeBases(val)}
              customStyle={{ width: "300px", maxHeight: "400px" }} />
          </div>

          <div className="llm-configuration-modal-dropdown">
            <InfoIconWithText
              text={"Web Enrichment"}
              helpBoxTitle={"Web Enrichment"}
              helpBoxText={
                "Whether to use web enrichment to retrieve information"
              }
              customInfoBoxStyle={{ top: "30px", left: "-20px" }}
            />
            <div style={{ marginTop: "8px" }}>
              <IosSwitch
                checked={useWebSearch}
                onChange={() => setUseWebSearch(!useWebSearch)}
              />
            </div>
          </div>
          {useWebSearch && (
            <div className="llm-configuration-modal-dropdown">
              <InfoIconWithText
                text={"Web search timeframe"}
                helpBoxTitle={"Web search timeframe"}
                helpBoxText={
                  "This is the timeframe before today that the web search will return results from."
                }
                customInfoBoxStyle={{ top: "30px", left: "-60px" }}
              />
              <CustomDropdown
                valueList={[
                  "past_day",
                  "past_week",
                  "past_month",
                  "past_year",
                  "any",
                ]}
                textList={[
                  "Past day",
                  "Past week",
                  "Past month",
                  "Past year",
                  "Any",
                ]}
                value={timeframe}
                sendDataToParent={(val) => setTimeframe(val)}
                customStyle={{ width: 160 }}
                isDisabled={!useWebSearch}
              />
            </div>
          )}
        </div>

        {useWebSearch && (
          <div className="llm-configuration-modal-message-container">
            <InfoIconWithText
              text={"Domains to search (optional)"}
              helpBoxTitle={"Domains to search"}
              helpBoxText={
                "A list of domains to constrain the search to. If left blank, the search will be unconstrained. Separate domains with a comma. Example: acme.inc, example.com, etc. Please note, these must be base urls (e.g. example.com) and not specific pages (e.g. example.com/page1)."
              }
            />
            <CustomTextArea
              placeholderText={"Acme.inc, example.com, ..."}
              customContainerStyle={{ marginTop: "0px" }}
              customTextAreaStyle={{
                padding: "12px 15px",
                marginTop: "0px",
                borderRadius: "5px",
                border: "1px solid var(--divider-line-gray)",
              }}
              sendDataToParent={(text) => setIncludeDomains(text)}
              text={includeDomains}
              minHeight={30}
            />
          </div>
        )}

        {useWebSearch && (
          <div className="llm-configuration-modal-message-container">
            <InfoIconWithText
              text={"Domains to exclude (optional)"}
              helpBoxTitle={"Domains to exclude"}
              helpBoxText={
                "A list of domains to exclude from the search. If left blank, no domains will be excluded. Separate domains with a comma. Example: acme.inc, example.com, etc. Please note, these must be base urls (e.g. example.com) and not specific pages (e.g. example.com/page1)."
              }
            />
            <CustomTextArea
              placeholderText={"Acme.inc, example.com, ..."}
              customContainerStyle={{ marginTop: "0px" }}
              customTextAreaStyle={{
                padding: "12px 15px",
                marginTop: "0px",
                borderRadius: "5px",
                border: "1px solid var(--divider-line-gray)",
              }}
              sendDataToParent={(text) => setExcludeDomains(text)}
              text={excludeDomains}
              minHeight={30}
            />
          </div>
        )}
        <div
          className="llm-configuration-modal-options"
          style={{ marginTop: "5px" }}
        >
          <div className="llm-configuration-modal-dropdown">
            <InfoIconWithText
              text={"Model name"}
              helpBoxTitle={"Model"}
              helpBoxText={
                "GPT-3.5-Turbo is the fastest and cheapest model. claude-3-opus is the highest performing model. Experiment with each one to find the best model for you use case."
              }
            />
            <CustomDropdown
              customStyle={{ width: 160 }}
              valueList={[
                "gpt-4",
                "gpt-3.5-turbo",
                "claude-3-haiku",
                "claude-3-opus",
                "claude-instant-1",
                "claude-2",
                "mistral-small",
                "mistral-medium",
              ]}
              textList={[
                "GPT-4 - Brainstorming and Short-form Blogs",
                "GPT 3.5 - Brainstorming and Short-form Blogs",
                "Haiku - Market Commentaries, Whitepapers and E-Books",
                "Opus - Social Media Posts, Articles and Newsletters",
                "Claude Instant: Short Social Media Posts",
                "Claude 2: Illustrations",
                "Mistral small: Short Stories",
                "Mistral Medium - Social Media Posts",
              ]}
              value={model}
              sendDataToParent={(value) => setModel(value)}
            />
          </div>

          <div className="llm-configuration-modal-dropdown">
            <InfoIconWithText
              text={"Response length"}
              helpBoxTitle={"Response length"}
              helpBoxText={lfgResponseLengtHelpText}
            />
            <CustomDropdown
              valueList={["short", "medium", "long"]}
              textList={["Short", "Medium", "Long"]}
              value={responseLength == undefined ? "medium" : responseLength}
              sendDataToParent={(val) => setResponseLength(val)}
              customStyle={{ width: 160 }}
            />
          </div>

          <div className="llm-configuration-modal-input">
            <InfoIconWithText
              text={"Temperature"}
              helpBoxTitle={"Temperature"}
              helpBoxText={
                "This controls the creativity of responses. Set this close to 0 reduce the risk of hallucinations, and closer to 1 for more creative responses."
              }
              customInfoBoxStyle={{ top: "30px", left: "-100px" }}
            />
            <CustomTextArea
              placeholderText={"0.2"}
              customContainerStyle={{ marginTop: "0px" }}
              customTextAreaStyle={{
                padding: "12px 15px",
                marginTop: "0px",
                borderRadius: "5px",
                border: "1px solid var(--divider-line-gray)",
              }}
              sendDataToParent={(text) => setTemperature(text)}
              text={temperature}
              minHeight={30}
            />
          </div>
        </div>

        <CustomTextArea
          placeholderText={
            "Write your firm's investment thesis or investment philosophy and tell the AI what kind of tone you want the content to sound like."
          }
          text={systemMessage}
          rows={4}
          sendDataToParent={(val) => setSystemMessage(val)}
          customTextAreaStyle={{
            backgroundColor: "white",
            height: "600px",
            maxHeight: "600px",
          }}
          customContainerStyle={{ marginBottom: "20px" }}
          maxChars={6000}
        />

        <CustomButton
          buttonText={"Save"}
          disabled={
            !systemMessage ||
            systemMessage.length === 0 ||
            temperature === "" ||
            temperature == undefined ||
            isNaN(temperature) ||
            temperature < 0 ||
            temperature > 1 ||
            temperature === "" ||
            responseLength === "" ||
            model === ""
          }
          onClick={() =>
            onClick(systemMessage, {
              use_web_search: useWebSearch,
              web_search_preset: {
                includeDomains: includeDomains,
                excludeDomains: excludeDomains,
                timeframe: timeframe,
              },
              model: model,
              temperature: temperature,
              response_length: responseLength,
              knowledge_base_ids: selectedKnowledgeBases,
            })
          }
        />
      </div>
    </CustomModal>
  );
};

export default Chat;
