import React, { useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { FaPen } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import {
  CustomAddButton,
  CustomButton,
  CustomCancelButton,
} from "../components/CustomButton";
import CustomDropdown, { CustomDropdownMultiple } from "../components/CustomDropdown";
import CustomModal from "../components/CustomModal";
import CustomTextArea from "../components/CustomTextArea";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { InfoIconWithText } from "../components/InfoIcon";
import QueriesAndResultsModal from "../components/QueriesAndResultsModal";
import Sidebar from "../components/Sidebar/Sidebar";
import { useGenerateHandler } from "../logic/generate";
import { Tooltip } from "@mui/material";
import {
  IoArrowRedoSharp,
  IoCheckmarkCircleOutline,
  IoCopyOutline,
} from "react-icons/io5";
import { useHistory } from "react-router-dom";
import BouncingDots from "../components/Loaders/BouncingDots/BouncingDots";
import { useToast } from "../context/ToastContext";
import { setMessageForReview } from "../features/compliance/complianceSlice.ts";
import { useAppDispatch, useAppSelector } from "../hooks.ts";
import { KnowledgeBaseType } from "../utils/knowledge_bases.ts";
import { longFormPresets } from "../utils/longFormPresets";
import "./LongForm.css";

const LongForm = () => {
  const theme = { theme: "light" };
  const { toggleToast } = useToast();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => state.auth.user);

  /*const knowledgeBaseIds = [
    data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.Compliance
    )[0].id,
    data.knowledge_bases.filter(
      (kb) => kb.name === KnowledgeBaseType.BrandPersonalization
    )[0].id
  ];*/
  const complianceKB = data.knowledge_bases.filter(
    (kb) => kb.name === KnowledgeBaseType.Compliance
  )[0];
  const knowledgeBaseIds = data.knowledge_bases.map((kb) => kb.id);
  const knowledgeBaseTitles = data.knowledge_bases.map((kb) => kb.name);

  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([complianceKB.id]);

  const messagesEndRef = useRef(null);

  const [model, setModel] = useState("gpt-4");
  const [targetSegmentLength, setTargetSegmentLength] = useState("medium");
  const [temperature, setTemperature] = useState(0.5);
  const [autoQueryGuidance, setAutoQueryGuidance] = useState("");
  const [responseLength, setResponseLength] = useState("medium");
  const [useWebSearch, setUseWebSearch] = useState(false);
  const [includeDomains, setIncludeDomains] = useState("");
  const [excludeDomains, setExcludeDomains] = useState("");
  const [timeframe, setTimeframe] = useState("any");

  const [queries, setQueries] = useState([]);
  const [results, setResults] = useState([]);
  const [citedResults, setCitedResults] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [showQueriesAndResultsModal, setShowQueriesAndResultsModal] =
    useState(false);

  const [promptText, setPromptText] = useState("");

  const [showConfigModal, setShowConfigModal] = useState(false);
  const [showPromptAndConfig, setShowPromptAndConfig] = useState(true);

  const [preset, setPreset] = useState("none");
  const [systemMessage, setSystemMessage] = useState(data.system_message ?? "");

  let presets = longFormPresets.map((item) => item.value);
  presets.unshift("none");
  let presetTitles = longFormPresets.map((item) => item.title);
  presetTitles.unshift("None");

  const [generationToDelete, setGenerationToDelete] = useState(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const [sectionEditing, setSectionEditing] = useState(null);
  const [updatedSectionTitle, setUpdatedSectionTitle] = useState(null);
  const [updatedSectionContent, setUpdatedSectionContent] = useState(null);

  const state = useAppSelector((state) => state);
  const {
    sendGeneratePrompt,
    resultData,
    requestData,
    isLoading,
    buttonDisabled,
    createNewGeneration,
    generations,
    currentGeneration,
    getGeneration,
    updateGeneration,
    deleteGeneration,
  } = useGenerateHandler(
    setShowPromptAndConfig,
    state.auth.idToken,
    state.auth.user._id,
    state.auth.user.chat_config?.use_web_search,
    state.auth.user.knowledge_bases,
    state.auth.user.chat_config?.web_search_preset
  );

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  function updatePreset(val) {
    console.log("val", val)
    setPreset(val);
    // Set the system message based on the use case
    if (val === "none") {
      setSystemMessage(data.system_message ?? "")
    } else {
      const updatedSytemMessage = longFormPresets.filter((item) => item.value === val)[0].systemMessage;
      setSystemMessage(updatedSytemMessage);
    }

  }


  function sendToCompliance(data) {
    // console.log("updatedSectionContent", updatedSectionContent);
    dispatch(setMessageForReview(data));
    history.push("/compliance");
  }

  function copyMessageText(content) {
    // Copy the text to clipboard
    navigator.clipboard.writeText(content);

    // Show the copied text for 3 seconds
    toggleToast("Copied to clipboard", false);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 3000);
  }

  function copyFullText() {
    // Loop through the sections and copy the title, content, and sources

    // Add in the title first
    let fullText = "";

    fullText += resultData["title"] + "\n\n";
    resultData["sections"].forEach((section, index) => {
      fullText += section["title"] + "\n\n";
      fullText += section["content"] + "\n\n";

      /*fullText += "Sources:\n";
      resultData["sources"][index].forEach((source) => {
        fullText += source["title"] + "\n";
      });*/
      if (resultData["webSources"][index].length > 0) {
        fullText += "Web sources:\n";
      }
      // Add in the web sources. We'll use the url for these
      resultData["webSources"][index].forEach((source) => {
        fullText += source["url"] + "\n";
      });
      fullText += "\n";
    });
    console.log("fullText", fullText)

    navigator.clipboard.writeText(fullText);

    // Show the copied text for 3 seconds
    //toggleToast("Copied to clipboard", false);
    setShowCopiedMessage(true);
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 3000);
  }

  function updateConfigOptions(
    modelInput,
    temperatureInput,
    targetSegmentLengthInput,
    autoQueryGuidanceInput,
    responseLengthInput,
    useWebSearchInput,
    includeDomainsInput,
    excludeDomainsInput,
    timeframeInput
  ) {
    setModel(modelInput);
    setTemperature(temperatureInput);
    setTargetSegmentLength(targetSegmentLengthInput);
    setResponseLength(responseLengthInput);
    setAutoQueryGuidance(autoQueryGuidanceInput);
    setUseWebSearch(useWebSearchInput);
    setIncludeDomains(includeDomainsInput);
    console.log("excludeDomainsInput", excludeDomainsInput);
    setExcludeDomains(excludeDomainsInput);
    setTimeframe(timeframeInput);

    setShowConfigModal(false);
  }

  function updateSectionTitle(text, sectionIndex) {
    console.log("sectionIndex", sectionIndex);
    setUpdatedSectionTitle(text);
  }

  function updateSectionContent(text, sectionIndex) {
    console.log("sectionIndex", sectionIndex);
    setUpdatedSectionContent(text);
  }

  function showQueriesAndResults(queries, results, sources, source) {
    setQueries(queries);
    setResults(results);
    setCitedResults(sources);
    setSelectedSource(source);
    setShowQueriesAndResultsModal(true);
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar currentPage={"long form"} onPersonalizationClick={() => setShowConfigModal(true)} />
      <div className={`generate-page-section`}>
        <div className={`long-form-page-main-container`}>
          {isLoading && <BouncingDots />}

          {showDeleteConfirmationModal && (
            <DeleteConfirmationModal
              isOpen={showDeleteConfirmationModal}
              closeModal={() => setShowDeleteConfirmationModal(false)}
              delete={() => {
                deleteGeneration(generationToDelete);
                setShowDeleteConfirmationModal(false);
              }}
              title={"Delete Long Form generation"}
              confirmationText={`Are you sure you want to delete this long form generation?`}
            />
          )}

          {showConfigModal && (
            <ConfigModal
              closeModal={() => setShowConfigModal(false)}
              isOpen={showConfigModal}
              onClick={(
                model,
                temperature,
                targetSegmentLength,
                autoQueryGuidance,
                responseLength,
                useWebSearch,
                includeDomains,
                excludeDomains,
                timeframe
              ) =>
                updateConfigOptions(
                  model,
                  temperature,
                  targetSegmentLength,
                  autoQueryGuidance,
                  responseLength,
                  useWebSearch,
                  includeDomains,
                  excludeDomains,
                  timeframe
                )
              }
              modelInput={model}
              temperatureInput={temperature}
              responseLengthInput={responseLength}
              targetSegmentLengthInput={targetSegmentLength}
              autoQueryGuidanceInput={autoQueryGuidance}
              useWebSearchInput={useWebSearch}
              includeDomainsInput={includeDomains}
              excludeDomainsInput={excludeDomains}
              timeframeInput={timeframe}
            />
          )}

          {showQueriesAndResultsModal && (
            <QueriesAndResultsModal
              onClose={() => setShowQueriesAndResultsModal(false)}
              queries={queries}
              citedResults={citedResults}
              allResults={results}
              theme={theme.theme}
              selectedSource={selectedSource}
            />
          )}

          <div id="generate-main-container">
            {resultData == null && (
              <div className="generate-input-container">
                {/* <div style={{ width: "100%" }}>
                  <p
                    onClick={() => setShowConfigModal(true)}
                    className={`generate-page-clickable-text-${theme.theme}`}
                  >
                    Edit model configuration
                  </p>
                </div> */}

                {resultData == null && requestData == null && (
                  <div
                    className="generate-input-row"
                    style={{ marginTop: "20px" }}
                  >

                    <div className="compliance-review-use-case-container">
                      <div>
                        <p className="use-case-header-text">Preset:</p>
                        <CustomDropdownMultiple
                          multiple={false}
                          valueList={presets}
                          textList={presetTitles}
                          value={preset}
                          sendDataToParent={(val) => updatePreset(val)}
                          customStyle={{ width: "200px", maxHeight: "400px" }}
                        />
                      </div>

                      <div className="kb-select-dropdown-container">
                        <p className="use-case-header-text">Knowledge bases:</p>
                        <CustomDropdownMultiple
                          valueList={knowledgeBaseIds}
                          textList={knowledgeBaseTitles}
                          value={selectedKnowledgeBases}
                          sendDataToParent={(val) => setSelectedKnowledgeBases(val)}
                          customStyle={{ width: "300px", maxHeight: "400px" }} />
                      </div>
                    </div>

                    <div className="query-text-input-container">
                      <InfoIconWithText
                        text={"Prompt"}
                        helpBoxTitle={"Prompt"}
                        helpBoxText={
                          "In this field, be specific about what you want to write. Try things like, 'Write me a blog post about <topic>.' or 'Write an article about <topic>.' You can also use this field to guide how the AI writes."
                        }
                      />
                      <CustomTextArea
                        placeholderText={"Prompt"}
                        text={promptText}
                        sendDataToParent={(text) => setPromptText(text)}
                        rows={8}
                        minHeight={240}
                      />
                    </div>
                    <CustomButton
                      buttonText="Generate"
                      disabled={
                        promptText.length === 0 ||
                        buttonDisabled
                      }
                      onClick={() =>
                        sendGeneratePrompt(
                          selectedKnowledgeBases,
                          model,
                          autoQueryGuidance,
                          promptText,
                          targetSegmentLength,
                          temperature,
                          responseLength,
                          systemMessage,
                          useWebSearch,
                          includeDomains,
                          excludeDomains,
                          timeframe,
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}

            <div className="generate-results-container">
              {resultData !== null && (
                <div className="generate-result-container">


                  <div
                    className="generate-intro-row"
                    style={{ display: "flex", marginTop: "40px" }}
                  >
                    <div>
                      <p
                        className={`bold-font-${theme.theme}`}
                        id="generation-title-text"
                      >
                        {resultData["title"]}
                      </p>
                    </div>

                    <IconContext.Provider
                      value={{ className: `copy-text-icon-light` }}
                    >
                      <div>
                        {showCopiedMessage ? (
                          <IoCheckmarkCircleOutline />
                        ) : (
                          <Tooltip
                            arrow
                            title="Copy Text"
                            placement="top"
                          >
                            <div onClick={() => copyFullText()}>
                              <IoCopyOutline id="copy-icon" />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </IconContext.Provider>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <p className={`semi-bold-font-${theme.theme}`}>
                      Generated on:
                    </p>
                    <p className={`regular-font-${theme.theme}`}>
                      {resultData["createdTimestamp"]}
                    </p>
                  </div>

                  {resultData["sections"].map((section, sectionIndex) => (
                    <div className="generate-section-row" key={sectionIndex}>
                      <div className="generate-section-title-row">
                        {sectionEditing !== sectionIndex && (
                          <p
                            className={`generate-section-title-${theme.theme}`}
                          >
                            {section["title"]}
                          </p>
                        )}

                        {sectionEditing === sectionIndex && (
                          <CustomTextArea
                            text={
                              updatedSectionTitle == null
                                ? section["title"]
                                : updatedSectionTitle
                            }
                            sendDataToParent={(text) => {
                              updateSectionTitle(text, sectionIndex);
                            }}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          {" "}
                          <div
                            className="copy-wrapper"
                            onClick={() => copyMessageText(section["content"])}
                          >
                            {sectionEditing !== sectionIndex && (
                              <IconContext.Provider
                                value={{ className: `copy-text-icon-light` }}
                              >
                                <div>
                                  {showCopiedMessage ? (
                                    <IoCheckmarkCircleOutline />
                                  ) : (
                                    <Tooltip
                                      arrow
                                      title="Copy Text"
                                      placement="top"
                                    >
                                      <div>
                                        <IoCopyOutline id="copy-icon" />
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                              </IconContext.Provider>
                            )}
                          </div>
                          <div
                            onClick={() => sendToCompliance(section["content"])}
                          >
                            <p className={`send-to-review-text`}>
                              send to review
                            </p>
                            {sectionEditing !== sectionIndex && (
                              <IconContext.Provider
                                value={{ className: `copy-text-icon-light` }}
                              >
                                <Tooltip
                                  arrow
                                  title="Complaince Review"
                                  placement="top"
                                >
                                  <div>
                                    <IoArrowRedoSharp />
                                  </div>
                                </Tooltip>
                              </IconContext.Provider>
                            )}
                          </div>
                          {sectionEditing !== sectionIndex && (
                            <IconContext.Provider
                              value={{
                                className: "generate-edit-section-icon",
                              }}
                            >
                              <Tooltip arrow title="Edit" placement="top">
                                <div
                                  onClick={() => {
                                    setSectionEditing(sectionIndex);
                                    setUpdatedSectionTitle(section["title"]);
                                    setUpdatedSectionContent(
                                      section["content"]
                                    );
                                  }}
                                >
                                  <FaPen />
                                </div>
                              </Tooltip>
                            </IconContext.Provider>
                          )}
                        </div>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        {sectionEditing !== sectionIndex && (
                          <p
                            className={`regular-font-${theme.theme}`}
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {section["content"]}
                          </p>
                        )}

                        {sectionEditing === sectionIndex && (
                          <CustomTextArea
                            text={updatedSectionContent}
                            sendDataToParent={(text) => {
                              updateSectionContent(text, sectionIndex);
                            }}
                          />
                        )}

                        {sectionEditing === sectionIndex && (
                          <div className="generate-edit-section-button-row">
                            <div className="generate-edit-section-cancel-button-col">
                              <CustomCancelButton
                                buttonText={"Cancel"}
                                onClick={() => {
                                  setSectionEditing(null);
                                  setUpdatedSectionTitle(null);
                                  setUpdatedSectionContent(null);
                                }}
                              />
                            </div>
                            <div className="generate-edit-section-update-button-col">
                              <CustomButton
                                buttonText={"Update"}
                                onClick={() => {
                                  updateGeneration(
                                    updatedSectionTitle,
                                    updatedSectionContent,
                                    sectionIndex
                                  );
                                  setSectionEditing(null);
                                  setUpdatedSectionTitle(null);
                                  setUpdatedSectionContent(null);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {resultData["sources"] &&
                        resultData["sources"][sectionIndex] &&
                        resultData["sources"][sectionIndex].length > 0 && (
                          <div className="generate-sources-container">
                            <div className="sources-col">
                              <p
                                className={`generate-sources-text-${theme.theme}`}
                              >
                                Sources:
                              </p>
                            </div>
                            <div className="sources-col">
                              {resultData["sources"][sectionIndex].map(
                                (source, index) => (
                                  <div
                                    key={index}
                                    className="source-row"
                                    onClick={() =>
                                      showQueriesAndResults(
                                        resultData["searchQueries"][
                                        sectionIndex
                                        ],
                                        resultData["rankedResults"][
                                        sectionIndex
                                        ],
                                        resultData["sources"][sectionIndex],
                                        source
                                      )
                                    }
                                  >
                                    <p className={`source-text-${theme.theme}`}>
                                      {source["title"]}
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}

                      {resultData["webSources"] &&
                        resultData["webSources"][sectionIndex] &&
                        resultData["webSources"][sectionIndex].length > 0 && (
                          <div className="generate-sources-container">
                            <div className="sources-col">
                              <p
                                className={`generate-sources-text-${theme.theme}`}
                              >
                                Web Sources:
                              </p>
                            </div>
                            <div className="sources-col">
                              {resultData["webSources"][sectionIndex].map(
                                (source, index) => (
                                  <div
                                    key={index}
                                    className="source-row"
                                    onClick={() =>
                                      showQueriesAndResults(
                                        resultData["webSearchQueries"][
                                        sectionIndex
                                        ],
                                        resultData["webRankedResults"][
                                        sectionIndex
                                        ],
                                        resultData["webSources"][sectionIndex],
                                        source
                                      )
                                    }
                                  >
                                    <p className={`source-text-${theme.theme}`}>
                                      {source["title"]}
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  ))}
                  <div ref={messagesEndRef}></div>
                </div>
              )}
            </div>
          </div>

          <div className={`generate-threads-container-${theme.theme}`}>
            <CustomAddButton
              buttonText={"New generation"}
              onClick={() => {
                setPromptText("");
                createNewGeneration()
              }}
              disabled={isLoading || resultData == null}
            />
            <div id="generations-list-container">
              {generations !== undefined &&
                generations.map((generation, index) => (
                  <div key={index}>
                    <Generation
                      title={generation["title"]}
                      onClick={() => {
                        getGeneration(generation);
                        setSectionEditing(null);
                      }}
                      onDeleteClick={() => {
                        setShowDeleteConfirmationModal(true);
                        setGenerationToDelete(generation);
                      }}
                      isCurrentGeneration={
                        currentGeneration !== null &&
                        currentGeneration["id"] == generation["job_id"]
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/*
<div style={{ width: "100%" }}>
                            <p onClick={() => setShowConfigModal(true)}
                                className={`generate-page-clickable-text-${theme.theme}`}>Edit model configuration
                            </p>
                        </div>

{showConfigModal &&
                    <ConfigModal
                        closeModal={() => setShowConfigModal(false)}
                        isOpen={showConfigModal}
                        onClick={(model, temperature, targetSegmentLength, autoQueryGuidance, responseLength) => updateConfigOptions(model, temperature, targetSegmentLength, autoQueryGuidance, responseLength)}
                        modelInput={model}
                        temperatureInput={temperature}
                        responseLengthInput={responseLength}
                        targetSegmentLengthInput={targetSegmentLength}
                        autoQueryGuidanceInput={autoQueryGuidance}
                    />
                }
                */

export const SelectedKnowledgeBaseCard = ({ title, theme }) => {
  return (
    <div className={`selected-knowledge-base-card-container-${theme.theme}`}>
      <p
        className={`bold-font-${theme.theme} selected-knowledge-base-card-title`}
      >
        {title}
      </p>
    </div>
  );
};

const RequestData = ({ requestData, knowledgeBases }) => {
  const theme = { theme: "light" };

  // Get the knowledge bases from the ids
  const kbIds =
    requestData["knowledge_base_ids"] == undefined
      ? []
      : requestData["knowledge_base_ids"];
  const selectedKnowledgeBases = knowledgeBases.filter((item) =>
    kbIds.includes(item.id)
  );

  return (
    <div style={{ marginBottom: "20px" }}>
      <p className={`semi-bold-font-${theme.theme}`}>Knowledge bases used</p>
      <div className="query-knowledge-bases-selected">
        {selectedKnowledgeBases.map((kb, index) => (
          <div key={index}>
            <SelectedKnowledgeBaseCard title={kb["title"]} theme={theme} />
          </div>
        ))}
      </div>

      <div className="request-data-row">
        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>RSE segment length</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["segment_length"] == ""
              ? '""'
              : requestData["segment_length"]}
          </p>
        </div>
      </div>

      <div className="request-data-row">
        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>AutoQuery guidance</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["auto_query_guidance"] == ""
              ? '""'
              : requestData["auto_query_guidance"]}
          </p>
        </div>
      </div>

      <div className="request-data-row">
        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>Model</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["model"]}
          </p>
        </div>
        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>Temperature</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["temperature"]}
          </p>
        </div>

        <div className="request-data-col">
          <p className={`semi-bold-font-${theme.theme}`}>Response length</p>
          <p className={`regular-font-${theme.theme}`}>
            {requestData["response_length"] == ""
              ? '""'
              : requestData["response_length"]}
          </p>
        </div>
      </div>
    </div>
  );
};

const Generation = ({
  title,
  onClick,
  onDeleteClick,
  disableClick,
  isCurrentGeneration = false,
}) => {
  const theme = { theme: "light" };

  return (
    <div
      className={`chat-thread-container-${theme.theme}${isCurrentGeneration
        ? ` chat-thread-container-selected-${theme.theme}`
        : ""
        }`}
    >
      <div
        className={
          "chat-thread-title-col" +
          (disableClick ? " chat-thread-title-col-disabled" : "")
        }
        onClick={disableClick ? null : onClick}
      >
        <p className={`semi-bold-font-${theme.theme} chat-thread-title-text`}>
          {title}
        </p>
      </div>
      <div
        className={
          "delete-chat-thread-col" +
          (disableClick ? " delete-chat-thread-col-disabled" : "")
        }
        onClick={disableClick ? null : onDeleteClick}
      >
        <IconContext.Provider
          value={{ className: `delete-chat-thread-icon-${theme.theme}` }}
        >
          <div>
            <MdDelete />
          </div>
        </IconContext.Provider>
      </div>
    </div>
  );
};

const ConfigModal = ({
  closeModal,
  isOpen,
  onClick,
  modelInput,
  temperatureInput,
  responseLengthInput,
  autoQueryGuidanceInput,
  useWebSearchInput = false,
  includeDomainsInput = "",
  excludeDomainsInput = "",
  timeframeInput = "any",
}) => {
  const theme = { theme: "light" };

  const lfgResponseLengtHelpText =
    "This parameter is used to control the final length of the response generated by the LLM. Short will create 2-4 sections with 2-4 paragraphs each, medium will create 3-5 sections with 3-5 paragraphs each, and long will create 5-7 sections with 3-5 paragraphs each.";

  // Round the temperature to the nearest 2 decimal places
  const formattedTemperature =
    temperatureInput == undefined
      ? 0.2
      : Math.round(temperatureInput * 100) / 100;
  const targetSegmentLength = "medium";

  const [model, setModel] = useState(
    modelInput == undefined ? "gpt-3.5-turbo" : modelInput
  );
  const [temperature, setTemperature] = useState(formattedTemperature);
  const [responseLength, setResponseLength] = useState(responseLengthInput);
  const [useWebSearch, setUseWebSearch] = useState(useWebSearchInput);
  const [includeDomains, setIncludeDomains] = useState(includeDomainsInput);
  const [excludeDomains, setExcludeDomains] = useState(excludeDomainsInput);
  const [timeframe, setTimeframe] = useState(timeframeInput);

  const [autoQueryGuidance, setAutoQueryGuidance] = useState(
    autoQueryGuidanceInput == undefined ? "" : autoQueryGuidanceInput
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    // temperature has to be a number between 0 and 1
    if (
      temperature == undefined ||
      isNaN(temperature) ||
      temperature < 0 ||
      temperature > 1 ||
      temperature === ""
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [temperature]);

  return (
    <CustomModal
      closeModal={closeModal}
      isOpen={isOpen}
      customModalContainerStyle={{
        maxWidth: "860px",
        maxHeight: "92vh",
        overflowY: "auto",
      }}
      customMainColStyle={{ paddingTop: "0px", marginTop: "-10px" }}
    >
      <div className="llm-configuration-modal-container">
        <div>
          <p
            className={`header-font-${theme.theme}`}
            style={{ fontSize: "18px" }}
          >
            {"Retrieval configuration"}
          </p>
        </div>

        {/* <div
          className="llm-configuration-modal-options"
          style={{ alignItems: "flex-start" }}
        >
          <div className="llm-configuration-modal-dropdown">
            <InfoIconWithText
              text={"Web search"}
              helpBoxTitle={"Web search"}
              helpBoxText={"Whether to use web search to retrieve information"}
              customInfoBoxStyle={{ top: "30px", left: "-30px" }}
            />
            <div style={{ marginTop: "8px" }}>
              <IosSwitch
                checked={useWebSearch}
                onChange={() => setUseWebSearch(!useWebSearch)}
              />
            </div>
          </div>

          <div className="llm-configuration-modal-dropdown">
            <InfoIconWithText
              text={"Web search timeframe"}
              helpBoxTitle={"Web search timeframe"}
              helpBoxText={
                "This is the timeframe before today that the web search will return results from."
              }
              customInfoBoxStyle={{ top: "30px", left: "-70px" }}
            />
            <CustomDropdown
              valueList={[
                "past_day",
                "past_week",
                "past_month",
                "past_year",
                "any",
              ]}
              textList={[
                "Past day",
                "Past week",
                "Past month",
                "Past year",
                "Any",
              ]}
              value={timeframe}
              sendDataToParent={(val) => setTimeframe(val)}
              customStyle={{ width: 160 }}
              isDisabled={!useWebSearch}
            />
          </div>
        </div>

        {useWebSearch && (
          <div className="llm-configuration-modal-message-container">
            <InfoIconWithText
              text={"Domains to search (optional)"}
              helpBoxTitle={"Domains to search"}
              helpBoxText={
                "A list of domains to constrain the search to. If left blank, the search will be unconstrained. Separate domains with a comma. Example: acme.inc, example.com, ..."
              }
            />
            <CustomTextInput
              placeholderText={"Acme.inc, example.com, ..."}
              customContainerStyle={{ marginTop: "0px" }}
              customTextAreaStyle={{
                backgroundColor: "white",
                padding: "12px 15px",
                marginTop: "0px",
                borderRadius: "5px",
                border: "1px solid var(--divider-line-gray)",
              }}
              sendDataToParent={(text) => setIncludeDomains(text)}
              text={includeDomains}
            />
          </div>
        )}

        {useWebSearch && (
          <div className="llm-configuration-modal-message-container">
            <InfoIconWithText
              text={"Domains to exlcude (optional)"}
              helpBoxTitle={"Domains to exclude"}
              helpBoxText={
                "A list of domains to exclude from the search. If left blank, no domains will be excluded. Separate domains with a comma. Example: acme.inc, example.com, ..."
              }
            />
            <CustomTextInput
              placeholderText={"Acme.inc, example.com, ..."}
              customContainerStyle={{ marginTop: "0px" }}
              customTextAreaStyle={{
                backgroundColor: "white",
                padding: "12px 15px",
                marginTop: "0px",
                borderRadius: "5px",
                border: "1px solid var(--divider-line-gray)",
              }}
              sendDataToParent={(text) => setExcludeDomains(text)}
              text={excludeDomains}
            />
          </div>
        )} */}

        <div className="llm-configuration-modal-message-container">
          <InfoIconWithText
            text={"AutoQuery guidance (optional)"}
            helpBoxTitle={"AutoQuery guidance"}
            helpBoxText={
              "When we automatically generate queries based on user input, you may want to provide some additional guidance and/or context to the system. For example, you may have some information that may not be input directly as query input, but you want the information present when generating search queries in the retrieval step."
            }
          />
          <CustomTextArea
            placeholderText={
              "Give the AI guidance on what kinds of search queries to generate."
            }
            rows={3}
            minHeight={80}
            customTextAreaStyle={{
              border: "1px solid var(--divider-line-gray)",
            }}
            sendDataToParent={(text) => setAutoQueryGuidance(text)}
            text={autoQueryGuidance}
          />
        </div>

        <div>
          <p
            className={`header-font-${theme.theme}`}
            style={{ fontSize: "18px", marginTop: "20px" }}
          >
            {"Response configuration"}
          </p>
        </div>

        <div
          className="llm-configuration-modal-options"
          style={{ marginTop: "5px" }}
        >
          <div className="llm-configuration-modal-dropdown">
            <InfoIconWithText
              text={"Model name"}
              helpBoxTitle={"Model"}
              helpBoxText={
                "GPT-3.5-Turbo is the fastest and cheapest model. claude-3-opus is the highest performing model. Experiment with each one to find the best model for you use case."
              }
            />
            <CustomDropdown
              customStyle={{ width: 160 }}
              valueList={[
                "gpt-4",
                "gpt-3.5-turbo",
                "claude-3-haiku",
                "claude-3-opus",
                "claude-instant-1",
                "claude-2",
                "mistral-small",
                "mistral-medium",
              ]}
              textList={[
                "GPT-4 - Brainstorming and Short-form Blogs",
                "GPT 3.5 - Brainstorming and Short-form Blogs",
                "Haiku - Market Commentaries, Whitepapers and E-Books",
                "Opus - Social Media Posts, Articles and Newsletters",
                "Claude Instant: Short Social Media Posts",
                "Claude 2: Illustrations",
                "Mistral small: Short Stories",
                "Mistral Medium - Social Media Posts",
              ]}
              value={model}
              sendDataToParent={(value) => setModel(value)}
            />
          </div>

          <div className="llm-configuration-modal-dropdown">
            <InfoIconWithText
              text={"Response length"}
              helpBoxTitle={"Response length"}
              helpBoxText={lfgResponseLengtHelpText}
            />
            <CustomDropdown
              valueList={["short", "medium", "long"]}
              textList={["Short", "Medium", "Long"]}
              value={responseLength == undefined ? "medium" : responseLength}
              sendDataToParent={(val) => setResponseLength(val)}
              customStyle={{ width: 160 }}
            />
          </div>

          <div className="llm-configuration-modal-input">
            <InfoIconWithText
              text={"Temperature"}
              helpBoxTitle={"Temperature"}
              helpBoxText={
                "This controls the creativity of responses. Set this close to 0 reduce the risk of hallucinations, and closer to 1 for more creative responses."
              }
              customInfoBoxStyle={{ top: "30px", left: "-100px" }}
            />
            <CustomTextArea
              placeholderText={"0.2"}
              customContainerStyle={{ marginTop: "0px" }}
              customTextAreaStyle={{
                padding: "12px 15px",
                marginTop: "0px",
                borderRadius: "5px",
                border: "1px solid var(--divider-line-gray)",
              }}
              sendDataToParent={(text) => setTemperature(text)}
              text={temperature}
              minHeight={30}
            />
          </div>
        </div>

        <div
          className="llm-configuration-modal-button-container"
          style={{ marginTop: "40px" }}
        >
          <CustomButton
            buttonText={"Confirm and close"}
            onClick={() =>
              onClick(
                model,
                temperature,
                targetSegmentLength,
                autoQueryGuidance,
                responseLength,
                useWebSearch,
                includeDomains,
                excludeDomains,
                timeframe
              )
            }
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </CustomModal>
  );
};

/*
<div className="llm-configuration-modal-dropdown">
                        <InfoIconWithText
                            text={"RSE segment length"}
                            helpBoxTitle={"RSE segment length"}
                            helpBoxText={"Only used when RSE is set to Yes."}
                            customInfoBoxStyle={{ top: "30px", left: "-20px" }}
                        />
                        <CustomDropdown
                            valueList={["very_short", "short", "medium", "long"]}
                            textList={["Very Short", "Short", "Medium", "Long"]}
                            value={targetSegmentLength == undefined ? "medium" : targetSegmentLength}
                            sendDataToParent={(val) => setTargetSegmentLength(val)}
                            customStyle={{ width: 160 }}
                        />
                    </div>
                    */

export default LongForm;


/*
{!showPromptAndConfig && (
                    <div style={{ marginBottom: "40px", marginTop: "0px" }}>
                      <p
                        onClick={() => setShowPromptAndConfig(true)}
                        className={`generate-page-clickable-text-${theme.theme}`}
                      >
                        View prompt and model configuration
                      </p>
                    </div>
                  )}

                  {showPromptAndConfig && (
                    <div style={{ marginBottom: "40px", marginTop: "0px" }}>
                      <p
                        onClick={() => setShowPromptAndConfig(false)}
                        className={`generate-page-clickable-text-${theme.theme}`}
                      >
                        Hide prompt and model configuration
                      </p>
                    </div>
                  )}

                  {requestData !== null && showPromptAndConfig && (
                    <div>
                      <RequestData
                        requestData={requestData}
                        knowledgeBases={knowledgeBases}
                      />

                      <div className="generate-problem-row">
                        <p className={`semi-bold-font-${theme.theme}`}>
                          Prompt
                        </p>
                        <p className={`regular-font-${theme.theme}`}>
                          {requestData["prompt"] == ""
                            ? resultData["prompt"]
                            : requestData["prompt"]}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="generate-divider-row"></div>
                  */