import { useState, useEffect } from "react";

import { formatSources, formatWebSources } from "./chat.tsx";
import { formatEpochToDate } from "../utils/dateFormatting";
import { capitalizeFirstLetter } from "../utils/generalFunctions";
import * as generateServices from "../services/longForm";
import { chat_and_long_form_kbs } from "../utils/knowledge_bases.ts";
//import * as generateActions from "../store/actions/generate";

export function breakResponseIntoChunks(currentResData, newResData) {
  // For simulating streaming, we need to determine which words we need to display next

  if (Object.keys(currentResData).length == 0) {
    return [[], null];
  }

  // Return an empty array if the sections is an empty list
  if (newResData["sections"].length == 0) {
    return [[], null];
  }

  // Loop through each section and see what content has changed. It's incredibly unlikely for the content of multiple
  // sections to change at a time.
  let oldSections = currentResData["sections"];
  let newSections = newResData["sections"];

  let newTextAdded = "";
  let sectionToUpdate = null;
  for (var i = 0; i < newSections.length; i++) {
    // First check if oldSections [index] existed
    if (oldSections == undefined || oldSections[i] == undefined) {
      // This is the section we care about
      console.log("no index for the old sections");
      newTextAdded = newSections[i]["content"];
      if (newTextAdded == "") {
        return [[], null];
      }
    }

    const currentText = oldSections[i]["content"];
    const newResponseText = newSections[i]["content"];
    newTextAdded = newResponseText.slice(currentText.length);

    if (newTextAdded !== "") {
      sectionToUpdate = i;
      break;
    }
  }

  // Split the new text into words
  const newWords = newTextAdded.split(" ");
  const numWordsToAdd = newWords.length;

  // Randomly choose between 1 and 4 words to add to each chunk
  let chunks = [];
  let numWordsAdded = 0;
  while (numWordsAdded < numWordsToAdd) {
    let numWords = Math.floor(Math.random() * 4) + 1;
    let newString = newWords.slice(0, numWords).join(" ");
    newString += numWordsAdded + numWords < numWordsToAdd ? " " : "";
    chunks.push(newString);
    newWords.splice(0, numWords);
    numWordsAdded += numWords;
  }

  // No point in returning a chunk that is an empty string
  if (chunks.length == 1 && chunks[0] == "") {
    chunks = [];
  }

  return [chunks, sectionToUpdate];
}

export function formatResponseData(resData, promptText) {
  const sections = resData["response"]["sections"];
  let allSources = [];
  let allQueries = [];
  let allRankedResults = [];
  let allWebSources = [];
  let allWebQueries = [];
  let allWebRankedResults = [];
  for (var i = 0; i < sections.length; i++) {
    const formattedSources = formatSources(
      sections[i]["references"],
      sections[i]["ranked_results"]
    );
    allSources.push(formattedSources);
    allQueries.push(sections[i]["search_queries"]);

    // Extract the metadata from the ranked results
    let rankedResults = [];
    if (sections[i]["ranked_results"] !== undefined) {
      for (let j = 0; j < sections[i]["ranked_results"].length; j++) {
        rankedResults.push(sections[i]["ranked_results"][j]["metadata"]);
      }
      allRankedResults.push(rankedResults);
    }

    const formattedWebSources = formatWebSources(
      sections[i]["web_references"],
      sections[i]["web_search_results"]
    );
    allWebSources.push(formattedWebSources);
    allWebQueries.push(sections[i]["web_search_queries"]);
    allWebRankedResults.push(sections[i]["web_search_results"]);
  }
  // Format the created on timestamp
  const createdTimestamp = formatEpochToDate(resData["created_on"]);
  const formattedResultData = {
    prompt:
      promptText == undefined ? resData["response"]["prompt"] : promptText,
    title: resData["response"]["title"],
    sections: sections,
    sources: allSources,
    searchQueries: allQueries,
    rankedResults: allRankedResults,
    webSources: allWebSources,
    webSearchQueries: allWebQueries,
    webRankedResults: allWebRankedResults,
    createdTimestamp: createdTimestamp,
  };

  return formattedResultData;
}

export function formatUpdatePayload(
  title,
  content,
  sectionIndex,
  currentGeneration
) {
  let sections = [];

  // For each section, we need to check if it's the section we want to update
  for (let i = 0; i < currentGeneration["response"]["sections"].length; i++) {
    const section = currentGeneration["response"]["sections"][i];
    if (i == sectionIndex) {
      // This is the section we want to update (we only care about the title and content)
      sections.push({
        content: content,
        title: title,
      });
    } else {
      sections.push({
        content: section["content"],
        title: section["title"],
      });
    }
  }

  return sections;
}

export function formatModelName(model) {
  // the model can come in as 'gpt-3.5-turbo-1106', or 'claude-3-opus-1106-preview'
  if (model == "gpt-3.5-turbo-1106") {
    return "GPT-3.5-Turbo";
  } else if (model == "claude-3-opus-1106-preview") {
    return "claude-3-opus";
  } else if (model == "mistral-small") {
    return "Mistral Small";
  } else if (model == "mistral-medium") {
    return "Mistral Medium";
  } else if (model == "claude-instant-1") {
    return "Claude Instant";
  } else if (model == "claude-2") {
    return "Claude 2";
  } else {
    return model;
  }
}

export function formatRequest(resData) {
  const request = resData["request"];
  let model = request == undefined ? "claude-3-opus" : request["model"];
  model = formatModelName(model);

  const formattedRequestData = {
    auto_query_guidance:
      request == undefined ? "" : request["auto_query_guidance"],
    knowledge_base_ids:
      request == undefined ? "" : request["knowledge_base_ids"],
    model: model,
    prompt: request == undefined ? "" : request["prompt"],
    response_length:
      request == undefined ? "medium" : request["response_length"],
    segment_length: request == undefined ? "medium" : request["segment_length"],
    temperature: request == undefined ? 0.5 : request["temperature"],
  };

  return formattedRequestData;
}

export function useGenerateHandler(
  setShowPromptAndConfig,
  idToken,
  username,
  use_web_search,
  knowledgeBaseIds,
  web_search_preset
) {
  const savedGenerations = null; //useSelector(state => state.generate.generateThreads);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [sectionUpdating, setSectionUpdating] = useState(0);

  // State variables for listing generations
  const [generations, setGenerations] = useState([]);
  const [currentGeneration, setCurrentGeneration] = useState(null);
  const [requestData, setRequestData] = useState(null);

  function createNewGeneration() {
    // Reset the current generation to null to indicate there isn't one selected
    resetParams();
  }
 
  function resetParams() {
    setCurrentGeneration(null);
    setResultData(null);
    setRequestData(null);
    setShowPromptAndConfig(true);
  }

  async function sendGeneratePrompt(
    selectedKnowledgeBaseIds,
    model,
    autoQueryGuidance,
    promptText,
    targetSegmentLength,
    temperature,
    responseLength,
    customSystemMessage
  ) {
    setButtonDisabled(true);
    setResultData(null);
    setIsLoading(true);
    const formattedPrompt = customSystemMessage + "\n\n" + promptText;
    
    let [resData, status] = await generateServices.generateLongForm(
      selectedKnowledgeBaseIds,
      idToken,
      model,
      autoQueryGuidance,
      formattedPrompt,
      targetSegmentLength,
      temperature,
      responseLength,
      username,
      use_web_search,
      web_search_preset
    );
    console.log("resData", resData);
    console.log("status", status);
    setShowPromptAndConfig(true);

    // Set the request data
    const formattedRequestData = {
      auto_query_guidance: autoQueryGuidance,
      knowledge_base_ids: selectedKnowledgeBaseIds,
      model: model,
      prompt: formattedPrompt,
      response_length: responseLength,
      segment_length: targetSegmentLength,
      temperature: temperature,
    };
    setRequestData(formattedRequestData);

    if (status === 202) {
      //dispatch(accountActions.setBillingEvents(null)); // resetting the billing events so we request them again

      const jobID = resData["id"];
      let currentGenerations = generations;

      const newGeneration = {
        job_id: jobID,
        prompt: formattedPrompt,
        status: "PENDING",
        title: "New generation", // temporary title
      };
      setGenerations([newGeneration, ...currentGenerations]);
      setCurrentGeneration(resData);
      console.log("updating generate threads in redux", [
        newGeneration,
        ...currentGenerations,
      ]);
      //dispatch(generateActions.setGenerateThreads([newGeneration, ...currentGenerations]))

      await pollPendingGeneration(jobID);
    } else if (status >= 400 && status < 500) {
      // Handle 4XX erros
      setIsLoading(false);
      alert(resData["error"]);
      resetParams();
    } else {
      // 500 errors
      setIsLoading(false);
      alert(
        "Sorry, we were unable to process your request at this time. Please contact support for assistance"
      );
      resetParams();
    }
    setIsLoading(false);
    setButtonDisabled(false);
  }

  async function pollPendingGeneration(jobID, promptText) {
    let didSetNewGeneration = false;
    let currentGenerations = generations;
    // Filter out the current generation if it's in the list, so we don't add it multiple times
    currentGenerations = currentGenerations.filter(
      (item) => item.job_id != jobID
    );

    let pollCount = 0;
    let responseStatus = "PENDING";
    let savedFormattedData = {}; // This will be the previous iterations response data
    while (responseStatus !== "COMPLETE" && pollCount < 900) {
      let [resData, status] = await generateServices.pollGenerateResponse(
        idToken,
        jobID
      );
      console.log("resData", resData);
      const model = resData["request"]["model"];
      responseStatus = resData["status"];
      const formattedData = formatResponseData(resData, promptText);

      if (
        formattedData["title"] !== "" &&
        formattedData["prompt"] !== "" &&
        !didSetNewGeneration
      ) {
        const newGeneration = {
          job_id: jobID,
          prompt: formattedData["prompt"],
          status: "PENDING",
          title: formattedData["title"],
        };
        setGenerations([newGeneration, ...currentGenerations]);
        setCurrentGeneration(resData);
        console.log("updating generate threads in redux", [
          newGeneration,
          ...currentGenerations,
        ]);
        //dispatch(generateActions.setGenerateThreads([newGeneration, ...currentGenerations]))
        didSetNewGeneration = true;
      }

      if (formattedData["sections"].length > 0) {
        setIsLoading(false);
      }

      let [chunks, sectionToUpdate] = breakResponseIntoChunks(
        savedFormattedData,
        formattedData
      );
      if (
        chunks.length > 0 &&
        sectionToUpdate !== null &&
        Object.keys(savedFormattedData).length > 0
      ) {
        // If the savedFormattedData is still an empty array, this would fail

        const sleepTime = (model == "claude-3-opus" ? 500 : 250) / chunks.length;

        let responseText =
          savedFormattedData["sections"][sectionToUpdate] == undefined
            ? ""
            : savedFormattedData["sections"][sectionToUpdate]["content"];
        for (let i = 0; i < chunks.length; i++) {
          // update the chat thread with the AI response in the state variable
          let newResultData = savedFormattedData;
          newResultData["sections"][sectionToUpdate]["content"] =
            responseText + chunks[i];
          setResultData(newResultData);
          console.log("newResultData", newResultData);

          setSectionUpdating(sectionToUpdate);
          responseText += chunks[i];
          await new Promise((r) => setTimeout(r, sleepTime));
        }
      }

      savedFormattedData = formattedData;

      // We need to set this here as well because we don't handle sources or anything else in the above loop.
      // This also handles the rare case where 2 sections could be updated at a time. The 2nd section
      // that updates won't have the simulated streaming, but that's fine
      setResultData(formattedData);

      // If the formattedData has sources for the current section, then we want to show the
      // thinking dots on the next section
      if (
        formattedData["sources"].length > 0 &&
        formattedData["sources"][sectionToUpdate] !== undefined &&
        formattedData["sources"][sectionToUpdate] !== null
      ) {
        setSectionUpdating(sectionUpdating + 1);
      }

      // Check for a status of FAILED
      if (responseStatus === "FAILED") {
        break;
      }
      pollCount += 1;

      if (responseStatus === "COMPLETE") {
        const formattedData = formatResponseData(resData);
        setCurrentGeneration(resData);
        setResultData(formattedData);
        setSectionUpdating(0); // reset this
        // Save this to session storage so it doesn't go away
        //sessionStorage.setItem("generateResultData", JSON.stringify(resData))
        break;
      }

      // Sleep for 0.5 seconds
      if (chunks.length < 1) {
        await new Promise((r) => setTimeout(r, model == "claude-3-opus" ? 500 : 250));
      }
    }
  }

  async function listGenerations() {
    setIsLoading(true);
    let [resData, status] = await generateServices.listGenerations(
      idToken,
      null,
      username
    );
    if (status == 200) {
      let longFormJobs = resData["long_form_jobs"];
      let nextPageToken = resData["next_page_token"];
      let formattedGenerations = longFormJobs;

      console.log("nextPageToken", nextPageToken);

      let count = 0;
      while (nextPageToken != null && count < 5) {
        [resData, status] = await generateServices.listGenerations(
          idToken,
          nextPageToken,
          username
        );
        if (status !== 200) {
          setIsLoading(false);
          alert(
            "Sorry, we are unable to request all of the generations at this time. Please contact support for assistance at support@superpowered.ai"
          );
        }
        const newNextPageToken = resData["next_page_token"];
        longFormJobs = resData["long_form_jobs"];
        let newFormattedGenerations = longFormJobs;

        // Concatenate the chat threads
        formattedGenerations = [
          ...formattedGenerations,
          ...newFormattedGenerations,
        ];
        nextPageToken = newNextPageToken;
        count += 1;
      }

      // sort the formattedGenerations by created on timestamp
      formattedGenerations.sort((a, b) => {
        return b.created_on - a.created_on;
      });
      formattedGenerations = formattedGenerations.filter(
        (item) => item.status !== "FAILED"
      );

      //dispatch(generateActions.setGenerateThreads(formattedGenerations))
      setGenerations(formattedGenerations);
    } else {
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  async function getGeneration(generation) {
    setIsLoading(true);
    const jobID = generation.job_id;
    const [resData, status] = await generateServices.getGeneration(
      idToken,
      jobID
    );
    setIsLoading(false);
    if (status == 200) {
      // Update the list of generations
      const formattedData = formatResponseData(resData);
      const formattedRequestData = formatRequest(resData);
      setRequestData(formattedRequestData);
      setResultData(formattedData);
      setCurrentGeneration(resData);
      const promptText =
        resData["request"] == undefined
          ? resData["response"]["prompt"]
          : resData["request"]["prompt"];

      // Check if the generation has a status of "PENDING"
      if (
        resData["status"] == "PENDING" ||
        resData["status"] == "IN_PROGRESS"
      ) {
        console.log("polling pending job");
        pollPendingGeneration(jobID, promptText);
      }
    }
  }

  async function updateGeneration(title, content, sectionIndex) {
    setIsLoading(true);
    console.log("currentGeneration", currentGeneration);
    const jobID = currentGeneration.id;
    //const updatedSections = formatUpdatePayload(title, content, sectionIndex, currentGeneration)
    const [resData, status] = await generateServices.updateGeneration(
      idToken,
      jobID,
      title,
      content,
      sectionIndex
    );
    setIsLoading(false);
    if (status == 200) {
      console.log("resData", resData);
      // Update the list of generations
      const formattedData = formatResponseData(resData);
      console.log("formattedData", formattedData);
      setResultData(formattedData);
      setCurrentGeneration(resData);
    }
  }

  async function deleteGeneration(generation) {
    setIsLoading(true);
    const jobID = generation.job_id;
    const status = await generateServices.deleteGeneration(idToken, jobID);
    setIsLoading(false);
    if (status == 204) {
      // Update the list of generations
      const newGenerations = generations.filter(
        (generation) => generation.job_id !== jobID
      );
      setGenerations(newGenerations);
      //dispatch(generateActions.setGenerateThreads(newGenerations))
      if (currentGeneration !== null && currentGeneration["id"] == jobID) {
        // Case where the user deleted the current generation
        setCurrentGeneration(null);
        setResultData(null);
        setRequestData(null);
        setShowPromptAndConfig(true);
      }
    }
    return status;
  }

  useEffect(() => {
    if (savedGenerations == null) {
      // Get the generations from the API
      listGenerations();
    } else {
      setGenerations(savedGenerations);
    }

    /*return () => {
            null;
        };*/
  }, []);

  return {
    sendGeneratePrompt,
    resultData,
    requestData,
    isLoading,
    buttonDisabled,
    createNewGeneration,
    generations,
    currentGeneration,
    getGeneration,
    updateGeneration,
    deleteGeneration,
  };
}

export function formatPendingExports(exports) {
  // exports will come in as [{download_url: "url", file_name: "file_name.md"}]

  let formattedExports = [];
  const allExportData = exports["pending_export_jobs"];
  for (let i = 0; i < allExportData.length; i++) {
    const exportData = allExportData[i];
    const downloadURL = exportData["response"]["download_url"];
    const fileName = exportData["request"]["file_name"];

    // Extract the type from the fileName
    let fileType = "";
    const extension = fileName.split(".")[1];
    if (extension == "md") {
      fileType = "Markdown";
    } else if (extension == "docx") {
      fileType = "Docx";
    } else if (extension == "mp3") {
      fileType = "Audio";
    }

    const type = exportData["type"];
    if (type == "LONG_FORM_AUDIO_EXPORT") {
      fileType = "Audio";
    }

    // Format the status
    const status = capitalizeFirstLetter(
      exportData["status"].split("_").join(" ")
    );

    formattedExports.push({
      downloadURL: downloadURL,
      fileName: fileName,
      type: fileType,
      status: status,
    });
  }

  return formattedExports;
}

export function formatExports(exports) {
  // exports will come in as [{download_url: "url", file_name: "file_name.md"}]
  // We mainly just want to extract the file type

  let formattedExports = [];
  for (let i = 0; i < exports.length; i++) {
    const exportData = exports[i];
    const downloadURL = exportData["download_url"];
    const fileName = exportData["file_name"];

    // Extract the type from the fileName
    let fileType = "";
    const extension = fileName.split(".")[1];
    if (extension == "md") {
      fileType = "Markdown";
    } else if (extension == "docx") {
      fileType = "Docx";
    } else if (extension == "mp3") {
      fileType = "Audio";
    }

    formattedExports.push({
      downloadURL: downloadURL,
      fileName: fileName,
      type: fileType,
      status: "Complete",
    });
  }

  return formattedExports;
}

export function useExportHandler() {
  // Get the idToken from the sessionStorage
  const idToken = sessionStorage.getItem("idToken");

  const [isLoading, setIsLoading] = useState(false);
  const [isPreviousExportLoading, setIsPreviousExportLoading] = useState(false);
  const [previousExports, setPreviousExports] = useState([]);

  async function exportToText(exportType, generationID) {
    setIsLoading(true);
    const [resData, status] = await generateServices.exportToText(
      idToken,
      generationID,
      exportType
    );
    console.log("resData", resData);
    setIsLoading(false);
    return [resData, status];
  }

  async function exportToAudio(
    audioVoice,
    generationID,
    setShowAudioDownloadMessage
  ) {
    setIsLoading(true);
    const [resData, status] = await generateServices.exportToAudio(
      idToken,
      generationID,
      audioVoice
    );
    console.log("resData", resData);

    // Poll for the response
    if (status == 202) {
      setIsLoading(false);
      setShowAudioDownloadMessage(true);
      // Show the message saying "Your download could take a few minutes. You can leave, but keep the tab open"

      let pollCount = 0;
      let pollStatus = resData["status"];
      const jobID = resData["id"];
      let downloadURL = "";
      let downloadStatus = 400;

      while (pollCount < 500 && pollStatus !== "COMPLETE") {
        // Get the new status
        const [resData, status] = await generateServices.pollExportJob(
          idToken,
          jobID
        );
        console.log("resData", resData);
        console.log("status", status);
        let newPollStatus = resData["status"];
        if (newPollStatus == "COMPLETE") {
          downloadURL = resData["response"]["download_url"];
          downloadStatus = 200;
        }
        pollStatus = newPollStatus;

        pollCount += 1;

        // Sleep for 1 a second
        await new Promise((r) => setTimeout(r, 1000));
      }

      if (pollStatus == "COMPLETE") {
        setIsLoading(false);
        return [downloadURL, downloadStatus];
      } else {
        setIsLoading(false);
        alert(
          "This request is taking longer than expected. Please come back later and try again."
        );
      }
    } else {
      setIsLoading(false);
      alert(
        "Sorry, we were unable to process your request at this time. Please contact support for assistance"
      );
      return [{}, 400];
    }
  }

  async function viewPreviousExports(generationID) {
    setIsPreviousExportLoading(true);
    setPreviousExports([]); // Reset the previous exports list
    const [resData, status] = await generateServices.listExports(
      idToken,
      generationID
    );
    const [pendingExportData, pendingStatus] =
      await generateServices.listPendingExports(idToken, generationID);
    console.log("resData", resData);
    let formattedExports = [];
    if (status == 200) {
      formattedExports = formatExports(resData);
      setPreviousExports(formattedExports);
    }
    if (pendingStatus == 200) {
      const formattedPendingExports = formatPendingExports(pendingExportData);
      console.log("formattedPendingExports", formattedPendingExports);
      console.log("[...formattedExports, formattedPendingExports]", [
        ...formattedExports,
        formattedPendingExports,
      ]);
      if (formattedPendingExports.length > 0) {
        setPreviousExports([...formattedExports, ...formattedPendingExports]);
      }
    }
    setIsPreviousExportLoading(false);
  }

  return {
    exportToText,
    exportToAudio,
    viewPreviousExports,
    previousExports,
    isLoading,
    isPreviousExportLoading,
  };
}
