import { WebSearchPreset } from "../features/auth/user_schema.ts";
import { includedDomains } from "../utils/included_domains.ts";
import { KnowledgeBase } from "../utils/knowledge_bases.ts";
import { Threads } from "./schema/threads_schema";

const { REACT_APP_AI_URL, REACT_APP_BACKEND_BASE_URL } = process.env;

interface Payload {
  knowledge_base_ids: string[];
  model: string;
  temperature: number;
  system_message: string;
  response_length: string;
}

export async function createChatThread(
  authToken: string,
  knowledgeBaseIds: string[] = [],
  model = "gpt-3.5-turbo",
  temperature = 0,
  response_length = "short",
  suppID = "",
  systemMessage = "",
  use_web_search: boolean = false,
  web_search_preset: WebSearchPreset | null = null
): Promise<[any, number]> {
  const payload: Payload = {
    knowledge_base_ids: knowledgeBaseIds,
    model: model,
    temperature: temperature,
    response_length: response_length,
    system_message: systemMessage,
  };

  const web_search_preset_config = {};
  if (use_web_search) {
    payload["use_web_search"] = true;
    if (web_search_preset && web_search_preset.include_domains) {
      if (web_search_preset.include_domains.length > 0) {
        web_search_preset_config["include_domains"] =
          web_search_preset.include_domains;
      }
    } else if (web_search_preset && web_search_preset.exclude_domains) {
      if (web_search_preset.exclude_domains.length > 0) {
        web_search_preset_config["exclude_domains"] =
          web_search_preset.exclude_domains;
      }
    }

    if (
      web_search_preset &&
      web_search_preset.timeframe_days &&
      typeof web_search_preset.timeframe_days === "number"
    ) {
      web_search_preset_config["timeframe_days"] =
        web_search_preset.timeframe_days;
    }
    payload["web_search_config"] = web_search_preset_config;
  }

  const response = await fetch(`${REACT_APP_AI_URL}/chat/threads`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    body: JSON.stringify({
      default_options: payload,
      supp_id: suppID,
    }),
  });

  const resData = await response.json();
  console.log(resData);
  return [resData, response.status];
}

export async function listChatThreads(
  authToken: string,
  username: string,
  nextPageToken: string | null
): Promise<[Threads, number]> {
  let url_postfix = `chat/threads`;
  if (nextPageToken) {
    url_postfix += `?next_page_token=${nextPageToken}`;
  }

  if (username) {
    if (nextPageToken) {
      url_postfix += `&supp_id=${username}`;
    } else {
      url_postfix += `?supp_id=${username}`;
    }
  }
  const response = await fetch(`${REACT_APP_AI_URL}/${url_postfix}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    // body: JSON.stringify({
    //     username: username,
    //     next_page_token: nextPageToken
    // })
  });
  const resData = await response.json();
  console.log("resData from request", resData);

  return [resData, response.status];
}

export async function deleteChatThread(
  authToken: string,
  threadID: string
): Promise<number> {
  const response = await fetch(`${REACT_APP_AI_URL}/chat/threads/${threadID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  // const resData = await response.json();
  return response.status;
}

export async function getChatThreadResponse(
  authToken: string,
  threadId: string,
  input: string,
  knowledge_base_ids: string[] = [],
  model: string = "gpt-3.5-turbo",
  temperature: number = 0,
  system_message: string = "",
  useRSE: boolean = true,
  targetSegmentLength: string = "medium",
  responseLength: string = "short",
  use_web_search: boolean = false,
  web_search_preset: WebSearchPreset | null = null
): Promise<[any, number]> {
  const defaultKnowledgeBaseIds = [
    KnowledgeBase.FINRA,
    KnowledgeBase.SEC,
    KnowledgeBase.Hybrid,
  ];

  /*if (knowledge_base_ids.length === 0) {
    knowledge_base_ids = defaultKnowledgeBaseIds;
  } else {
    knowledge_base_ids = knowledge_base_ids.concat(defaultKnowledgeBaseIds);
  }*/
  const payload = {
    async: true,
    input: input,
    model: model,
    temperature: temperature,
    use_rse: useRSE,
    segment_length: targetSegmentLength,
    system_message: system_message,
    knowledge_base_ids: knowledge_base_ids,
    response_length: responseLength,
  };

  const web_search_preset_config = {};
  if (use_web_search) {
    // payload["use_web_search"] = true;
    // payload["web_search_config"] = {
    //   include_domains: includedDomains,
    // };
    payload["use_web_search"] = true;
    if (web_search_preset && web_search_preset.include_domains) {
      if (web_search_preset.include_domains.length > 0) {
        web_search_preset_config["include_domains"] =
          web_search_preset.include_domains;
      }
    } else if (web_search_preset && web_search_preset.exclude_domains) {
      if (web_search_preset.exclude_domains.length > 0) {
        web_search_preset_config["exclude_domains"] =
          web_search_preset.exclude_domains;
      }
    }

    if (
      web_search_preset &&
      web_search_preset.timeframe_days &&
      typeof web_search_preset.timeframe_days === "number"
    ) {
      web_search_preset_config["timeframe_days"] =
        web_search_preset.timeframe_days;
    }
    payload["web_search_config"] = web_search_preset_config;
  }
  
  console.log("payload", payload);

  const response = await fetch(
    `${REACT_APP_AI_URL}/chat/threads/${threadId}/get_response`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(payload),
    }
  );

  const resData = await response.json();
  console.log(resData);
  return [resData, response.status];
}

export async function pollChatResponse(
  authToken: string,
  id: string
): Promise<[any, number]> {
  const response = await fetch(
    // pollURL,
    // https://api.superpowered.ai/v1/jobs/ad642699-d6aa-4752-be3d-f66321d3c516
    `${REACT_APP_AI_URL}/jobs/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    }
  );

  const resData = await response.json();
  console.log("poll response", resData);
  return [resData, response.status];
}

export async function getChatThreadInteractions(
  authToken: string,
  threadId: string,
  nextPageToken: string = ""
) {
  let url = `${REACT_APP_AI_URL}/chat/threads/${threadId}/interactions?limit=10`;
  if (nextPageToken !== "") {
    url += `&next_page_token=${nextPageToken}`;
  }

  console.log("authToken", authToken);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
  });

  const resData = await response.json();
  console.log("interactions", resData);
  return [resData, response.status];
}

export const updateSystemMessage = async (
  authToken: string,
  userId: string,
  companyId: string,
  systemMessage: string,
  // use_web_search: boolean
  chat_config
) => {
  const payload = {
    system_message: systemMessage,
    chat_config: chat_config,
  };

  

  const response = await fetch(
    `${REACT_APP_BACKEND_BASE_URL}/company/${companyId}/fieldTrainers/${userId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    }
  );
  return response.status;
};
export const updateKnowledgeBase = async (
  authToken: string,
  userId: string,
  companyId: string,
  knowledge_bases: any[]
) => {
  const payload = {
    knowledge_bases: knowledge_bases,
  };

  const response = await fetch(
    `${REACT_APP_BACKEND_BASE_URL}/company/${companyId}/fieldTrainers/${userId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(payload),
    }
  );
  return response.status;
};
